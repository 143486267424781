<template>
    <div>
        <div class="toolBar mBottom10">
            <el-button type="primary" size='small' plain @click="handleReset">新增</el-button>
            <el-button plain size='small' class='fr' @click="refresh">刷新</el-button>
        </div>

        <el-row :gutter='10'>
            <el-col :span='6'>
                <el-card class="box-card">
                    <div slot="header">
                        <span>视图权限</span>
                    </div>
                    <div class="leftTree">
                        <el-select v-model="permissionType" @change="refresh" placeholder="请选择" class="w100"
                                   style="margin-bottom: 20px">
                            <el-option v-for="item in global.DictionaryData['pmViewType']" :key="item.value"
                                       :label="item.label"
                                       :value="item.value">
                            </el-option>
                        </el-select>

                        <el-tree :data="roleTree"
                                 node-key="id"
                                 default-expand-all
                                 :expand-on-click-node="false"
                                 @node-click="handleNodeClick"
                                 highlight-current
                                 check-strictly
                                 :props="defaultProps">
                            <div class="custom-tree-node"
                                 style=' flex: 1;display: flex;align-items: center;justify-content: space-between;'
                                 slot-scope="{ node, roleTree }">
                                <span>{{ node.label }}</span>
                                <el-button type="text" size="mini" class='hkDanger' @click="delConfirm(node.data.id)">
                                    删除
                                </el-button>
                            </div>
                        </el-tree>
                    </div>

                </el-card>
            </el-col>
            <el-col :span='18' class='handleForm'>
                <el-card class="box-card">
                    <div slot="header">
                        <span>视图权限详情</span>
                    </div>
                    <el-form :rules="rules" ref="form" :model="form" label-width="100px" size='small'
                             style='padding-right: 120px;'>
                        <el-form-item label="父级权限名称">
                            <el-cascader
                                    v-model="form.parentId"
                                    size="medium "
                                    :options="options"
                                    :props="selectProps"
                                    class="w100">
                            </el-cascader>
                        </el-form-item>
                        <el-form-item label="权限类型" prop="type">
                            <el-select v-model="form.type" placeholder="请选择" class="w100">
                                <el-option v-for="item in global.DictionaryData['pmViewType']" :key="item.value"
                                           :label="item.label"
                                           :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="权限名称" prop="name">
                            <el-input v-model="form.name" clearable></el-input>
                        </el-form-item>
                        <el-form-item label="权限关键字" prop="keyWord">
                            <el-input v-model="form.keyWord" clearable></el-input>
                        </el-form-item>
                        <el-form-item label="链接地址" prop="url">
                            <el-input v-model="form.url" clearable></el-input>
                        </el-form-item>

                        <el-form-item label="优先序号" prop="sort">
                            <el-input v-model="form.sort" clearable></el-input>
                        </el-form-item>
                        <el-form-item label="是否公开" prop="isOpen">
                            <el-select v-model="form.isOpen" clearable placeholder="请选择" class="w100">
                                <el-option label="是" :value="1"></el-option>
                                <el-option label="否" :value="0"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="公众号" prop="appId">
                            <el-select v-model="form.appId" clearable placeholder="请选择" class="w100" value-key="value" multiple>
                                <el-option
                                    v-for="item in appList"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="描述">
                            <el-input v-model="form.memo" clearable></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button size='small' @click="handleReset">重置</el-button>
                            <el-button type="primary" size='small' @click="onsubmit">保存</el-button>
                        </el-form-item>
                    </el-form>
                </el-card>
                <el-card class="box-card mTop10" v-if="form.id">
                    <div slot="header">
                        <span>{{form.name}}-操作权限</span>
                    </div>
                    <div>
                        <div v-for="item in operateList" :key="item.id" class="item">
                            <div style="color: #606266;line-height: 30px;margin-bottom: 10px">{{item.name}}</div>
                            <div style="color: #909399;font-size:10px;height:35px;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 2;
                                overflow: hidden;">{{item.keyWord}}
                            </div>
                            <div class="icon">
                                <img style="margin-right: 10px" src="../../../public/img/icons/icon-edit.png"
                                     @click="showUpdDlg(item)">
                                <img src="../../../public/img/icons/icon-delete.png" @click="del(item.id)">
                            </div>
                        </div>
                        <div class="item" @click="isShowAddDlg = true">
                            <div style="height: 18px"></div>
                            <img src="../../../public/img/icons/icon-add.png">
                        </div>
                    </div>
                </el-card>
            </el-col>
        </el-row>

        <el-dialog title="新增操作权限"
                   v-dialogDrag
                   :visible.sync="isShowAddDlg"
                   width="50%">

            <el-form ref="formOperate" :model="formOperate" :rules="rules" label-width="100px" size="small">

                <el-form-item label="权限名称" prop="name">
                    <el-input v-model="formOperate.name" clearable maxlength="14" class="w100"></el-input>
                </el-form-item>

                <el-form-item label="权限类型" prop="type">
                    <el-select v-model="formOperate.type" placeholder="请选择" class="w100">
                        <el-option v-for="item in global.DictionaryData['pmOperateType']" :key="item.value" :label="item.label"
                                   :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="权限关键字" prop="keyWord" class="w100">
                    <el-input v-model="formOperate.keyWord" clearable></el-input>
                </el-form-item>

                <el-form-item label="权限描述" prop="memo" class="w100">
                    <el-input clearable v-model="formOperate.memo" type="textarea" resize="none" :rows="2"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
					<el-button @click="isShowAddDlg = false"> 取消 </el-button>
					<el-button type="primary" @click="submitAdd"> 保存 </el-button>
				  </span>
        </el-dialog>

        <el-dialog title="修改操作权限"
                   v-dialogDrag
                   :visible.sync="isShowUpdDlg"
                   width="50%">

            <el-form ref="formOper" :model="formOper" :rules="rules" label-width="100px" size="small">

                <el-form-item label="权限名称" prop="name">
                    <el-input clearable v-model="formOper.name" class="w100"></el-input>
                </el-form-item>

                <el-form-item label="权限类型" prop="type">
                    <el-select v-model="formOper.type" placeholder="请选择" class="w100">
                        <el-option v-for="item in global.DictionaryData['pmOperateType']" :key="item.value" :label="item.label"
                                   :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="角色关键字" prop="keyWord" class="w100">
                    <el-input clearable v-model="formOper.keyWord"></el-input>
                </el-form-item>

                <el-form-item label="权限描述" prop="memo" class="w100">
                    <el-input clearable v-model="formOper.memo" type="textarea" resize="none" :rows="2"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
					<el-button @click="isShowUpdDlg = false">取消</el-button>
					<el-button type="primary" @click="submitUpdate">保存</el-button>
				  </span>
        </el-dialog>
    </div>
</template>

<script>
    class FormItems {
        constructor() {
            this.id = null;
            this.parentId = 0;
            this.type = null;
            this.keyWord = null;
            this.name = null;
            this.url = null;
            this.sort = null;
            this.memo = null;
            this.isOpen = null;
            this.appId = null;
        }

        reset() {
            this.constructor()
        }
    }

    class FormOperate {
        constructor() {
            this.name = null;
            this.type = null;
            this.keyWord = null;
            this.viewPmId = null;
            this.memo = null;
        }

        reset() {
            this.constructor()
        }
    }

    export default {
        name: 'permissionConfig',
        data() {
            return {
                isShowAddDlg: false,
                isShowUpdDlg: false,
                form: new FormItems(),
                formOperate: new FormOperate(),

                roleTree: [],
                defaultProps: {
                    children: 'children',
                    label: 'name',
                    id: 'id'
                    // checkStrictly:true
                },

                options: [],
                selectProps: {
                    children: 'children',
                    label: 'name',
                    value: "id",
                    checkStrictly: true,
                    expandTrigger: 'hover'
                },

                rules: {
                    type: [{required: true, message: '权限类型是必须的', trigger: 'blur'}],
                    name: [{required: true, message: '权限名称是必须的', trigger: 'blur'}],
                    keyWord: [{required: true, message: '权限关键字是必须的', trigger: 'blur'}],
                    url: [{required: true, message: '链接地址是必须的', trigger: 'blur'}],
                    sort: [{required: true, message: '优先序号是必须的', trigger: 'blur'}],
                    isOpen: [{required: true, message: '是否公开是必须的', trigger: 'blur'}],
                },

                permissionType: 1,
                operateType: 0,
                operateList: [],
                formOper: [],
                appList: [],
            }
        },
        computed: {

        },
        methods: {
            async load() {
                let param = {type: this.permissionType};
                let res = await this.$http.post('/sys/permissionViewList', param);
                if (res.state) {
                    this.roleTree = res.data;
                    this.options = res.data;
                }
            },
            async addPermissionView() {
                if(typeof(this.form.parentId)==='object'){
                    this.form.parentId = this.form.parentId.pop() || 0;
                }
                if (this.form.appId && this.form.appId.length == 0) {
                    this.form.appId = null
                }
                let res = await this.$http.post("/sys/addPermissionView", this.form);
                if (res.state) {
                    this.$message.success('新增视图权限成功');
                    this.load();
                    this.handleReset();
                }
            },
            async updatePermissionView() {
                if(typeof(this.form.parentId)==='object'){
                    this.form.parentId = this.form.parentId.pop() || 0;
                }
                if (this.form.appId && this.form.appId.length == 0) {
                    this.form.appId = null
                }
                let res = await this.$http.post("/sys/updPermissionView", this.form);
                if (res.state) {
                    this.$message.success('更新视图权限成功');
                    this.load();
                    this.handleReset();
                }
            },
            async delPermission(id) {
                let res = await this.$http.post("/sys/delPermissionView", {id});
                if (res.state) {
                    this.$message.success('删除视图权限成功');
                    this.load();
                    this.handleReset();
                }
            },
            delConfirm(id) {
                this.$confirm('此操作将永久删除该视图权限, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.delPermission(id);
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            },
            async handleNodeClick(data) {       //显示详情
                this.form.reset();
                Object.assign(this.form, data);
                if (this.form.appId) {
                    this.form.appId = this.form.appId.split(',')
                }
                console.log(this.form)
                this.getOperateList();
            },
            async delOperate(id) {
                let res = await this.$http.post("/sys/delPermissionOperate", {id});
                if (res.state) {
                    this.$message.success("删除操作权限成功");
                    this.getOperateList();
                }
            },
            del(id) {
                this.$confirm('此操作将永久删除该操作权限, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.delOperate(id);
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            },
            handleReset() {
                this.onReset();
                this.form.reset();
            },
            onReset() {
                if (this.$refs['form'] !== undefined) {
                    this.$refs['form'].resetFields();
                }
            },
            onsubmit() {
                this.$refs['form'].validate((valid) => {
                    if (valid) {//表单验证通过
                        if (this.form.id == null) {
                            this.addPermissionView();
                        } else {
                            this.updatePermissionView();
                        }
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            showUpdDlg(data) {
                this.formOper = data;
                this.isShowUpdDlg = true;
            },
            async getOperateList() {
                let param = {viewPmId: this.form.id};
                let res = await this.$http.post("/sys/permissionOperatorList", param);
                if (res.state) {
                    this.operateList = res.data;
                }
            },
            async updOperate() {
                let res = await this.$http.post("/sys/updPermissionOperate", this.formOper);
                if (res.state) {
                    this.isShowUpdDlg = false;
                }
            },
            async addOperate() {
                this.formOperate.viewPmId = this.form.id;
                let res = await this.$http.post("/sys/addPermissionOperate", this.formOperate);
                if (res.state) {
                    this.$message.success("新增操作权限成功");
                    this.getOperateList();
                    this.formOperate.reset();
                    this.isShowAddDlg = false;
                }
            },
            async getAppList() {
                let res = await this.$http.post('/mp/getWxMpCombo');
                if (res.state) {
                    this.appList = res.data;
                }
            },
            submitUpdate() {
                this.$refs['formOper'].validate((valid) => {
                    if (valid) {//表单验证通过
                        this.updOperate();
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            submitAdd() {
                this.$refs['formOperate'].validate((valid) => {
                    if (valid) {//表单验证通过
                        this.addOperate();
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            refresh() {
                this.handleReset();
                this.load();
                this.getAppList();
            },
        },
        mounted() {
            /*this.getoptions('pmViewType');
            this.getoptions('pmOperateType');*/
            this.load();
            this.getAppList();
        }
    }
</script>

<style scoped>
    .leftTree {
        height: calc(100vh - 277px);
        overflow-x: auto;
        overflow-y: auto;
    }

    .handleForm {
        height: calc(100vh - 177px);
        overflow: auto;
    }

    .item {
        border: 1px solid #DCDFE6;
        display: block;
        text-align: center;
        padding: 10px;
        width: 220px;
        height: 110px;
        margin: 20px;
        float: left;
    }

    .icon {
        margin-top: 15px;
        text-align: right;
    }
</style>